import React from 'react';
import { Link } from "react-router-dom";

const NotFound = () => {
    return (
        <div className="not-found">
            
            <h2>Ruh roh, can't find that. :( </h2>
            <p>Page can't be found.</p>
            <Link to='/'>Back to Home</Link>
        </div>
      )
    ;
}
 
export default NotFound;