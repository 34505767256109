import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'

// components
// import Navbar from './components/Navbar';

// pages to route to
import Home from './pages/Home';
import Messages from './pages/Messages';
import NotFound from './pages/NotFound';
import Professional from './pages/Professional';
import Projects from './pages/Projects';
import Plots from './pages/Plots'
import { Container, NavDropdown } from 'react-bootstrap';
import Exercise from './pages/Exercise';






function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Container fluid="md">
          <Navbar bg="secondary" expand="md" className="navbar navbar-expand-md fixed-top">
            <Container>
              <Navbar.Brand href="/">🧔‍♂️ Bearded Alex</Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                  <NavDropdown title="🗂️ Professional " id="basic-nav-dropdown">
                    <NavDropdown.Item href="/professional/#overview">Overview</NavDropdown.Item>
                    <NavDropdown.Item href="/professional/#resume">Resume</NavDropdown.Item>
                    <NavDropdown.Item href="/professional/#recommendations">Recommendations</NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link href="/projects">📋 Projects </Nav.Link>
                  <Nav.Link href="/messages">📟 Blog </Nav.Link>
                  <Nav.Link href="/exercise">💪 Exercise </Nav.Link>
                </Nav>

            </Navbar.Collapse>
            </Container>
          </Navbar>
        </Container>
        <div className="pages">
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/professional' element={<Professional />} />
            <Route path='/projects' element={<Projects />} />
            <Route path='/messages' element={<Messages />} />
            <Route path='/exercise' element={<Exercise />} />
            <Route path="/*" element={<NotFound />} />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;






// function App() {
//   return (
//     <div className="App">
      
//       <BrowserRouter>
//         <Navbar />
//         <div className="pages">
//           <Routes>
//             <Route
//               path='/'
//               element={<Home />}
//             />
//             <Route 
//               path='/pagetwo'
//               element={<PageTwo />}
//             />
//           </Routes>
//         </div>
//       </BrowserRouter>


//     </div>
//   );
// }

// export default App;
