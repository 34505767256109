import React, { useState, useEffect } from 'react';
import Plot from 'react-plotly.js';

const CallSimulatorDaily = () => {
  const [totalDailyCalls, setTotalDailyCalls] = useState(700);
  const [callData, setCallData] = useState([]);
  const [hourlyCalls, setHourlyCalls] = useState([]);

  const getTomorrow = () => {
    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1); // Set the date to tomorrow's date
    return tomorrow;
  };

  const formatTime = (hour, minute, second) => {
    const tomorrow = getTomorrow();
    const year = tomorrow.getFullYear();
    const month = (tomorrow.getMonth() + 1).toString().padStart(2, '0'); // January is 0!
    const day = tomorrow.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day} ${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}:${second.toString().padStart(2, '0')}`;
  };

  const poissonRandomNumber = (lambda) => {
    let L = Math.exp(-lambda);
    let k = 0;
    let p = 1;

    do {
      k++;
      p *= Math.random();
    } while (p > L);

    return k - 1;
  };

  const simulateCallArrivals = (totalDailyCalls, hours, startTime = '00:00:00') => {
    const hourlyPattern = [0.05, 0.03, 0.02, 0.01, 0.01, 0.02, 0.03, 0.05, 0.07, 0.09, 0.1, 0.1, 0.1, 0.09, 0.1, 0.09, 0.08, 0.06, 0.06, 0.05, 0.04, 0.03, 0.02, 0.02];
    const ratePerHour = hourlyPattern.map(percentage => totalDailyCalls * percentage);
    const [startHour, startMinute, startSecond] = startTime.split(':').map(Number);
    const totalMinutes = hours * 60;
    let calls = [];

    for (let minute = 0; minute < totalMinutes; minute++) {
      let currentHour = (startHour + Math.floor(minute / 60)) % 24;
      let numberOfCalls = poissonRandomNumber(ratePerHour[currentHour] / 60);

      for (let call = 0; call < numberOfCalls; call++) {
        let currentMinute = (startMinute + minute) % 60;
        let currentSecond = Math.floor(Math.random() * 60);
        let timestamp = formatTime(currentHour, currentMinute, currentSecond);
        calls.push({ arrivalTime: timestamp, answered: false });
      }
    }

    return calls;
  };

  const countCallsPerHour = (calls) => {
    let hourlyCallCounts = Array(24).fill(0);
    calls.forEach(call => {
      let hour = parseInt(call.arrivalTime.split(' ')[1].split(':')[0], 10);
      hourlyCallCounts[hour]++;
    });
    return hourlyCallCounts; 
  };

  const exportToCSV = () => {
    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent += "Call Number,Arrival Time\n";

    callData.forEach(function (call, index) {
      csvContent += `${index + 1},${call.arrivalTime}\n`;
    });

    let encodedUri = encodeURI(csvContent);
    let link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "call_data.csv");
    document.body.appendChild(link);
    link.click();
  };

  const handleResimulate = () => {
    const newCalls = simulateCallArrivals(totalDailyCalls, 24);
    setCallData(newCalls);
    const newHourlyCalls = countCallsPerHour(newCalls);
    setHourlyCalls(newHourlyCalls);
    console.log(newHourlyCalls)
  };

  useEffect(() => {
    handleResimulate();
  }, [totalDailyCalls]);

  const plotData = [
    {
      x: Array.from({ length: 24 }, (_, i) => `${i.toString().padStart(2, '0')}:00`),
      y: hourlyCalls,
      type: 'bar',
      marker: {
        color: 'rgba(54, 162, 235, 0.5)', // Here '0.5' is the opacity level for semi-transparency
      },
    },
  ];







// Define the layout configuration outside the component or inside if it depends on the state


const customLayout = {
  title: 'Call Arrivals',
  template: 'ggplot2',
  // font: {
  //   family: 'Lato, sans-serif',
  //   size: 14,
  //   color: '#333',
  // },
  autosize: true,
  margin: {
    l: 90,
    r: 90,
    b: 80,
    t: 80,
    pad: 4
  },
  showlegend: false,

  xaxis: {
    showticklabels: false,
    tickmode: 'array',
    tickvals: Array.from({ length: 24 }, (_, i) => i),
    ticktext: Array.from({ length: 24 }, (_, i) => `${i === 0 ? 12 : i > 12 ? i - 12 : i} ${i >= 12 ? 'PM' : 'AM'}`),

    tickangle: 0,
    tickfont: {
      family: 'Lato, sans-serif',
      size: 12,
      color: 'grey'
    },
    title: {
      // text: 'Time of Day',
      font: {
        family: 'Lato, sans-serif',
        size: 16,
        color: '#333'
      },
    },
  },
  yaxis: {
    fixedrange: true,
    showgrid: false,
    showticklabels: false,
    // tickfont: {
    //   family: 'Lato, sans-serif',
    //   size: 12,
    //   color: 'grey'
    // },
    title: {
      // text: 'Number of Calls',
      font: {
        family: 'Lato, sans-serif',
        size: 16,
        color: '#333'
      },
    },
  },
  // paper_bgcolor: 'rgba(255,255,255,1)',
  // plot_bgcolor: 'rgba(255,255,255,1)',
  // hovermode: 'closest',
  // template: 'ggplot2',
  // barmode: 'group',
  // bargap: 0.1,
  // bargroupgap: 0.1,
  // bar: {
  //   color: 'rgba(55, 128, 191, 0.7)',
  //   outliercolor: 'rgba(219, 64, 82, 0.6)',
  //   outlierwidth: 2
  // }
};


const plotConfig = {
  displayModeBar: false, // this will hide the mode bar
};
  

  
  return (
    <div style={{ display: 'block', width: '100%' }}>
      <Plot
        data={plotData}
        layout={customLayout}
        config={plotConfig}
        useResizeHandler={true}
        style={{ width: '100%', height: '100%' }}
      />
      <input
        type="number"
        value={totalDailyCalls}
        onChange={(e) => setTotalDailyCalls(Number(e.target.value))}
      />
      <button onClick={handleResimulate}>
        Resimulate Call Arrivals
      </button>
      <button onClick={exportToCSV}>
        Export Call Data to CSV
      </button>
    </div>
  );
  

};

export default CallSimulatorDaily;



