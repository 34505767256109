import React from 'react';
const RecommendationCard = ({ recco }) => {
    

    
    return (  
        <div className='card' >
        
            {/* <img src="..."></img> */}
            <div className="card-body">
                <h5 className="card-title">{recco.name}</h5>
                <p className="card-text">{recco.company}</p>
                <p dangerouslySetInnerHTML={{__html: recco.review_text}} />
     

            </div>    

        </div>
        
    );
}
 
export default RecommendationCard;