import React from "react";
import supabase from "../config/supabaseClient"
import { useEffect, useState } from "react"
import { Card, Container, Figure } from "react-bootstrap";
import ExerciseComponent from "../components/ExerciseComponent";
import PlotDailyChart from "../components/PlotDailyChart";


const Exercise = () => {

    const [fetchError, setFetchError] = useState(null) 
    const [exercises, setExercises] = useState([])



    useEffect(() => {
        const fetchExercises = async () => {
            const { data, error } = await supabase
                .from('enriched_exercise_data')
                .select()
            if (error) {
                console.log(error)
                setFetchError('Could not fetch.')
                setExercises(null)
            }
            if (data) {
                setExercises(data)
                setFetchError(null)
            }
        }
        fetchExercises()
    }, [])

    const theDates = []
    const theDurations = []
    const theMaDurations = []

    let dataToBePassed = exercises.map(exercise => {
        theDates.push(exercise.date)
        theDurations.push(exercise.sets)
        theMaDurations.push(exercise.sets_14_ma)
    })



    return ( 
        <Figure>
            <PlotDailyChart dates={theDates} durations={theDurations} maDurations={theMaDurations}/>
        </Figure>
     );
}
 
export default Exercise;