import React from 'react';

const Home = () => {
    return (
        <main role="main" className="container">
            <h1 className='display-1'>This is the primary home page.</h1>
        </main>
      );
}
 
export default Home;