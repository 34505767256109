import React from "react";
import CallSimulator from "../components/CallSimulatorDaily";


const Projects = () => {
    return ( 
        <div>
            <h1>Here are the Projects</h1>
            <CallSimulator />
        </div>
     );
}
 
export default Projects;