import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from 'react-bootstrap';
import supabase from "../config/supabaseClient";
// import Highlighter from "react-highlight-words";


const Resume = () => {

    const [fetchError, setFetchError] = useState(null) 
    const [resumeEntries, setResumeEntries] = useState([])
    const [filteredResumeEntries, setFilteredResumeEntries] = useState([])
    const [searchText, setSearchText] = useState('')


    useEffect(() => {
        const fetchResumeEntries = async () => {
            const { data, error } = await supabase
                .from('resume')
                .select()
            if (error) {
                console.log(error)
                setFetchError('Could not fetch.')
                setResumeEntries(null)
            }
            if (data) {
                setResumeEntries(data)
                setFilteredResumeEntries(data)
                setFetchError(null)
            }
        }
        fetchResumeEntries()
    }, [])

    const handleInput = (e) => {
        setSearchText(e.target.value.toLowerCase())
      }


    const filtered = resumeEntries.filter(entry => {
        return (
            entry.company.toLowerCase().includes(searchText) ||
            entry.role.toLowerCase().includes(searchText)
        )
        }
      )

    const resumes = filtered.map(entry => 
        <div>
            <div>
                <small className="text-muted"><em>{entry.date_start} - {entry.date_end}</em></small>


                <h4>
                    <strong>{entry.role} </strong>
                    <small><a href={entry.company_link}>{entry.company}</a></small>
                    {/* <strong>{entry.json_test.map(entry => <li>{entry}</li>)} </strong> */}
                </h4>
            </div>
            <h5>Key Accomplishments</h5>
            <p className="lead">{entry.accomplishments.lead}</p>
                <ul>{entry.accomplishments.ages.map(value => <li>{value}</li>)}</ul>
            <h5>Responsibilities</h5>
                <ul>{entry.accomplishments.ages.map(value => <li>{value}</li>)}</ul>
            <ol>
                <li>Alex taught me a huge amount about Workforce Management</li>
                <li>Alex generously walked me through close technicalities of current planning (even line-by-line SQL review where I had qs)</li>
                <li>Alex shared sticky high-level wisdom from his wide ranging experience across roles in the WFM domain </li>
                <li>Alex generously built capacity in me and his other WFM team members: this flowed through multiple hops as I've now been able to onramp several other generalist data analysts and another data scientist who had no previous experience in the WFM space</li>
                <li>Alex would "teach a team to fish" where possible but he also never hesitated to solve (heaps of) thorny time-critical issues when he could knock them out faster than anyone else</li>
                <li>I've never encountered another biz-side partner who provided the level of careful documentation Alex does (his practice is honestly better than many full-time Data folks as well)</li>
                <li>I've worked at two tech startups that think a lot about how to quickly get the most critical work done fast and reliably: my top favorite corporate values from each are simply day-to-day habits of how Alex approaches his work.</li>
            </ol>
            <h5>Skills and Tools</h5>
            <p>Finally, with the context that I personally have a PhD in an engineering field and I worked for 5 years as a Math/CS professor at top schools:</p>
            <ol>
                <li>Alex is a very strong analytical thinker (both in debugging smaller ad hoc issues and in setting up and implementing well-structured planning systems and dashboards)</li>
                <li>Alex is a really eager and fast learner: in addition to his appetite to understand pretty subtle issues in the volume forecasts I owned, I saw Alex repeatedly pursue totally new directions when he saw a chance to create value for Stripe (e.g. collaborating directly with ENG to build out real-time queue observability in SignalFX even when this appeared way beyond where we were operating to our partner analyst team)</li>
                <li>Alex is a very savvy and pragmatic planner who has an instinct about where a quick back-of-the-envelope approach captures most of the value vs. where treating an issue carefully save substantial cost (in contrast to some analytical people who will overbuild things that can should be treated simply)</li>
                <li>Alex has 0 ego about what he doesn't know yet (and sets this tone in a way that empowers others to ask questions and learn faster): Alex brought me ideas he was thinking about looking for a technical sounding board. He always summarized where he was in his reasoning in a useful way and had carefully distilled his questions for me. Many of the qs Alex brought me about improvements he was developing would be great interview questions for "analytical reasoning" loops for data analysts and scientists.</li>
            </ol>
            <p>I can't recommend Alex highly enough, and would jump at the opportunity to work with him again!</p>
        </div>
    )
    
    return (
        <Container>
            <Row xs={1} sm={1} md={1} lg={2}>
                <Col className="order-last order-lg-first">
                    {resumes}
                </Col>
                <Col lg={4} xl={4} xxl={4}>
                    <p>
                        <Card>
                            <Card.Header>Search and Filter</Card.Header>
                            <Card.Body><input onChange={(handleInput)}></input></Card.Body>
                            <Card.Body>Other stuff</Card.Body>
                        </Card>
                    </p>
                </Col>
            </Row>
        </Container>

        
    );
}
 
export default Resume;






// {
//     "lead": "Stripe was a challenging environment — growth was exceptional.",
//     "ages": [
//       "Managed the headcount",
//       "Planned for the things"
//     ],
//     "price": 5.95,
//     "description": "Puppy is slower than other, bigger animals."
//   }