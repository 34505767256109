import React from 'react';
import supabase from "../config/supabaseClient"
import { useEffect, useState } from "react"
import { Card, Col, Container, Row } from 'react-bootstrap';


// components
import RecommendationCard from "../components/RecommendationCard"



const Recommendations = () => {
    
    const [fetchError, setFetchError] = useState(null) 
    const [reccos, setReccos] = useState(null)
    const [reviewCount, setReviewCount] = useState(null)
    const [searchText, setSearchText] = useState('')
    const searchWords = searchText.split(/\s/).filter(word => word)

    const handleInput = (e) => {
      setSearchText(e.target.value)
    }


    useEffect(() => {
        const fetchReccos = async () => {
            const { data, error } = await supabase
                .from('recommendations')
                .select()
                .ilike('review_text', '%'.concat(searchText).concat('%'))

            if (error) {
                console.log(error)
                setFetchError('Could not fetch.')
                setReccos(null)
            }

            if (data) {
                setReccos(data)
                setReviewCount(data.length)
                setFetchError(null)
            }
        }

        fetchReccos()
    }, [searchText])
    
    
    
    return (
        <Container>
 
            {fetchError && <p>{fetchError}</p>}
            {reccos && 
            (
              <Row xs={1} sm={1} md={1} lg={2}>
                <Col className="order-last order-lg-first">
                  <div>
                      {reccos.map(recco => (
                        <RecommendationCard key={recco.id} recco={recco} />
                      ))}
                  </div>
                </Col>
                <Col lg={4} xl={4} xxl={4}>
                  <Card>
                      <Card.Header>Search and Filter</Card.Header>
                      <Card.Body><input onChange={(handleInput)}></input></Card.Body>
                      <Card.Body>Review Count: {reviewCount}</Card.Body>
                  </Card>
                </Col>
              </Row>
            )}
        </Container>
      )
}
 
export default Recommendations;