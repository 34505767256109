import React from 'react';
import supabase from "../config/supabaseClient"
import { useEffect, useState } from "react"

// components
import MessageCard from "../components/MessageCard"
import { Card, Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';



const Messages = () => {
    
    const [fetchError, setFetchError] = useState(null) 
    const [messages, setMessages] = useState([])


    useEffect(() => {
        const fetchMessages = async () => {
            const { data, error } = await supabase
                .from('sms')
                .select()
                .order('id',  {ascending: false })

            if (error) {
                console.log(error)
                setFetchError('Could not fetch.')
                setMessages(null)
            }

            if (data) {
                console.log(data)
                setMessages(data)
                setFetchError(null)
            }
        }

        fetchMessages()
    }, [])
    
    
    
    return (
        <Container className="container body">
          <Row xs={1} sm={2} md={3} lg={4} >
            {fetchError && <p>{fetchError}</p>}
            {messages && (
              messages.map(message => (
              <Card>
                <Card.Img style={{ height: 'auto' }} variant="top" src={message.sms_media_url} />
                <Card.Title>{message.sms_from}</Card.Title>
                <Card.Text>{message.sms_body}</Card.Text>
              </Card>
              ))

      

            )}
          </Row>
        </Container>

      )
}
 
export default Messages;