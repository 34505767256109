import React from 'react';
import PostTitles from './PostTitles';





const Plots = () => {
    return ( 
        <PostTitles />
     );
}
 
export default Plots;




