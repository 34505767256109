import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import Recommendations from "./Recommendations";
import Resume from './Resume';



const Professional = () => {
    return ( 


        <Container fluid="sm">
            <Row>
                <section id="overview"/>
                <h2>Overview</h2>
                <p className="lead">I have had the pleasure of working at a number of fast-scaling startups and companies.</p>
                <p>The companies and teams I have operated on are scrappy and blah blah. This is a further description of that sort of stuff</p>
                <p>There's a lot more to it! Look at some of these accomplishments, etc.</p>
            </Row>
            <Row>
                <section id="resume"/>
                <h2>Resume</h2>
            </Row>
            <Row>
                <Col>
                    <Resume />
                </Col>
            </Row>
            <Row>
                <section id="recommendations"/>
                <h2>Recommendations</h2>
            </Row>
            <Row>
                <Col>
                    <Recommendations />
                </Col>
            </Row>

      </Container>

        
     );
}
 
export default Professional;