import React from 'react';
import Plot from 'react-plotly.js';



const theDates = []
const theDurations = []
const theMaDurations = []




const PlotDailyChart = ({ dates, durations, maDurations }) => {
    // exercises.map(exercise => {
    //     theDates.push(exercise.date)
    //     theDurations.push(exercise.duration)
    //     theMaDurations.push(exercise.ma_duration)
    // })


    return ( 
        <Plot
        data={
            [
                {
                name: "Requirements",
                type: 'bar',
                x: dates,
                y: durations,
                yaxis: "y1",
                },
                { 
                name: "Occupancy",
                type: "line", 
                x: dates, 
                y: maDurations, 
                yaxis: "y1" 
                },
          
        ]}
        useResizeHandler
        style={{height: '100%', width:'100%'}}
        //   data={this.data}
        //   layout={this.layout}
        config={{responsive: true}}
        // useResizeHandler={true}
        // useResizeHandler={true}
        // style={{width: "100%", height: "100%"}}
        // layout={ 
        //     {
        //     // barmode: "stack",
        //     // autosize: true,
        //     // width: '100%',
        //     // height: '100%', 
        //     showlegend: false,
        //     autosize: true,
           
        //     title: 'A Fancy Plot', 
        //     xaxis: {
        //         title: 'Date',
        //         // ticktext: theDates,
        //         // tickvals: theDates,
        //         showgrid: false,
        //         type: 'category'
        //         // range: 'auto'
        
        //     },
        //     yaxis: {
        //         title: {
        //             text: 'Duration',
        //             standoff: 20
        //             },
        //         showline: true,
        //         // range: 'auto'
        //     }
            // yaxis2: {
            //     showline: false,
            //     overlaying: 'y',
            //     side: 'right',
            //     range: [0, 1],
            //     tickformat: '0.1%',
            // }
            // }}
        // config={{displayModeBar: false,}}
        // config={{responsive: true}}
        // layout={{autosize: true, title: 'A Fancy Plot'}}

      />
     );
}
 
export default PlotDailyChart;



